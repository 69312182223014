<template>
    <div>
        我是专才
        <!-- 步骤1 按需引入各个组件 -->
        <!-- 步骤2 -->
        <!-- 步骤3 -->
        <!-- 步骤4 -->
    </div>
</template>

<script>
export default {
    name: 'Expert',
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
    },
}
</script>

<style lang="less" scoped>

</style>